let origin = window.ORIGIN;
// 增加/编辑
const addOrUpdateInfoUrl = `${origin}/gateway/hc-neighbour/radioTelevision/addOrUpdateInfo`;
// 删除
const deleteByIdUrl = `${origin}/gateway/hc-neighbour/radioTelevision/deleteById`;
// 获取详情
const getDetailsUrl = `${origin}/gateway/hc-neighbour/radioTelevision/getDetails`;
// 获取列表
const getListUrl = `${origin}/gateway/hc-neighbour/radioTelevision/getList`;
// 点赞
const addPraiseURL = `${origin}/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `${origin}/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `${origin}/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `${origin}/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `${origin}/gateway/hc-neighbour/topic/getTopicReplyList`;
export {
  addOrUpdateInfoUrl,
  deleteByIdUrl,
  getDetailsUrl,
  getListUrl,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
};
