<template>
  <div class="page">
    <div class="tab">
      <div
        @click="changeType(2)"
        :class="['watch-tv', { active: active == 2 }]"
      >
        看电视 <img v-show="active == 2" src="./img/active-icon.png" alt="" />
      </div>
      <div
        @click="changeType(1)"
        :class="['listen-radio', { active: active == 1 }]"
      >
        听广播<img v-show="active == 1" src="./img/active-icon.png" alt="" />
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        @click="toDetail(item)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="img">
          <img :src="item.iconPicture" alt="" />
        </div>
        <div class="name">{{ item.name }}</div>
        <img
          v-if="item.type == 2"
          class="icon"
          src="./img/tv-icon.png"
          alt=""
        />
        <img v-else class="icon" src="./img/radio-icon.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  addOrUpdateInfoUrl,
  deleteByIdUrl,
  getDetailsUrl,
  getListUrl,
} from "./api.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
export default {
  name: "watchTvListenRadio",
  data() {
    return {
      active: 2,
      list: [],
    };
  },
  async mounted() {
    gloabalCount("", 20, 1);
    await this.getList(2);
  },
  methods: {
    toDetail(item) {
      let params = {
        id: item.id,
      };
      this.$axios.get(`${getDetailsUrl}`, { params }).then((res) => {
        if (res.code == 200) {
          this.$router.push({
            name: "watchTvListenRadioDetail",
            query: {
              id: res.data.topicId,
              detailId: item.id,
            },
          });
        }
      });
    },
    async changeType(type) {
      this.active = type;
      this.list = [];
      await this.getList(type);
    },
    async getList(typeNum) {
      let params = {
        type: typeNum,
        curPage: 1,
        pageSize: 5000,
      };
      let res = await this.$axios.get(`${getListUrl}`, { params });
      if (res.code == 200) {
        this.list = res.data.records;
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  .list {
    padding: 0 50px;
    box-sizing: border-box;
    .item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 50px;
      .icon {
        width: 28px;
        height: 28px;
      }
      .img {
        width: 100px;
        height: 100px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        flex: 1;
        font-size: 28px;
        padding-right: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .tab {
    display: flex;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    align-items: center;
    padding: 30px 36px 78px 36px;
    box-sizing: border-box;
    .watch-tv,
    .listen-radio {
      margin-right: 94px;
      position: relative;
      img {
        width: 28px;
        display: block;
        position: absolute;
        top: -15px;
        right: -15px;
      }
    }
    .active {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
</style>
